import finesApi from '@rosfines/vue-common/common/api/finesApi'
import config from '@/config'
import webToNativeBridgeClient from '@rosfines/vue-common/common/services/webToNativeBridgeClient'

// TODO: переписать на использование класса вместо хранения истории в переменной вне компонента export default
let _eventsHistory = []
let utmSource = ''
let featuresLoaderResolve
const featuresLoader = new Promise(resolve => {
	featuresLoaderResolve = resolve
})
console.log(new Date().getTime())
const isTrackingExtraParams =
	(await webToNativeBridgeClient.isActionAvailable(
		'action_get_analytic_tracking_ext_info'
	)) || false
console.log(isTrackingExtraParams)
if (isTrackingExtraParams) {
	const trackingInfo = await webToNativeBridgeClient.getResult(
		'action_get_analytic_tracking_ext_info'
	)
	if (trackingInfo.history) {
		_eventsHistory = trackingInfo.history
	}
	if (trackingInfo.utmSource) {
		utmSource = trackingInfo.utmSource
	}
}
console.log(new Date().getTime())

export default {
	featuresLoader,
	featuresLoaderResolve,
	featuresList: [],

	async trackEvent(event, params = {}, ymEnabled = false) {
		try {
			const { userId, session } = finesApi.getRequestParams()
			await this.featuresLoader

			const data = {
				userId,
				event,
				params,
				session,
				channel: 'WebVersion',
				features: this.featuresList.join(', '),
			}
			if (!data.params.history) {
				data.params.history = _eventsHistory.slice(0, 5).map((item, index) => {
					const res = {}
					res[index + 1] = item
					return res
				})
			}
			// если в прошлом запросе или при инициализации натив вернул utmSource, то перезапрашиваем, актуален ли он
			// если utmSource стал неактуален, то считаем пусты до перезапуска веб-приложения
			if (isTrackingExtraParams && utmSource) {
				const trackingInfo = await webToNativeBridgeClient.getResult(
					'action_get_analytic_tracking_ext_info'
				)
				if (trackingInfo.utmSource) {
					utmSource = trackingInfo.utmSource
					data.source = utmSource
				} else {
					utmSource = ''
				}
			}
			_eventsHistory.push(event)
			if (_eventsHistory.length > 5) {
				_eventsHistory = _eventsHistory.slice(_eventsHistory.length - 5)
			}

			if (
				process.env.NODE_ENV !== 'production' ||
				localStorage.getItem('debugMode')
			) {
				console.log(
					`%c${event} - ${data?.params?.action}`,
					'color: white; background: #448aff; padding: 2px',
					data
				)
			}

			if (ymEnabled && window.ym) {
				window.ym(config.YM_SITEKEY, 'reachGoal', event)
			}

			return await finesApi.post(`/userAction/tracking`, JSON.stringify(data))
		} catch (error) {
			console.error(error)
		}
		return false
	},
}
