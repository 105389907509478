export const FINE_STATUS = {
	NOT_PAID: 'notpaid',
	IN_PROGRESS: 'in_progress',
	CLOSED: 'closed',
	PAID: 'paid',
	EXPIRED: 'expired',
	ADDITIONAL_ACCRUAL: 'additionalAccrual',
	MOVED_TO_ORDER: 'movedToOrder',
}

export const STATUTE_OF_LIMITATIONS = '110'
