import taxesApi from '@rosfines/vue-common/common/api/finesApi'

let gettingFinesList
let gettingFinesListResolve
let gettingFinesListReject
const resetGettingFinesState = () => {
	gettingFinesList = new Promise((resolve, reject) => {
		gettingFinesListResolve = resolve
		gettingFinesListReject = reject
	})
}
resetGettingFinesState()

const taxTypeToString = typeNumber => {
	typeNumber = Number(typeNumber)

	switch (typeNumber) {
		case 1:
			return 'transport'
		case 2:
			return 'realty'
		case 3:
			return 'land'
		case 6:
			return 'ipfssp'
		case 4: // patent
		case 5: // insurance
		case 7: // enp
		default:
			return 'default'
	}
}

export default {
	namespaced: true,
	state() {
		return {
			ordinancesIds: [],
			chosenOrdinancesIds: [],
			ordinancesList: [],
			closedTaxes: [],
			notpaidTaxes: [],
			unitedTaxesList: [],
			activeTabLabel: '#',
		}
	},
	getters: {
		chosenTaxesIds(state) {
			return state.ordinancesIds.reduce((accumulator, id) => {
				accumulator[id] = state.ordinancesList.find(
					ordinance => ordinance.id === id
				)
				return accumulator
			}, [])
		},
	},
	mutations: {
		setActiveTab(state, tabLabel) {
			state.activeTabLabel = tabLabel
		},
		setTaxesList(state, taxesList) {
			state.ordinancesList = taxesList
			for (const i in state.ordinancesList) {
				state.ordinancesList[i].id = parseInt(state.ordinancesList[i].id)
			}
			state.closedTaxes = state.ordinancesList.filter(ordinance =>
				['closed', 'paid'].includes(ordinance.status)
			)
			state.notpaidTaxes = state.ordinancesList.filter(
				ordinance => !['closed', 'paid'].includes(ordinance.status)
			)
			gettingFinesListResolve()
		},
		setUnitedTaxesList(state, unitedTaxesList) {
			unitedTaxesList.sort(
				(a, b) => (a?.document?.number || 0) - (b.document?.number || 0)
			)
			unitedTaxesList.forEach(item => {
				item.id = parseInt(item.id, 10)
			})
			state.unitedTaxesList = unitedTaxesList
			gettingFinesListResolve()
		},
		setAmountForOrdinances(state, data) {
			state.amountForOrdinancesResponse = data
			if (state.totalOld === null) {
				state.totalOld = Math.floor(data.totalAmount / 100)
			}
		},
		setOrdinancesIds(state, ids) {
			const intIds = ids.map(id => parseInt(id, 10))
			state.ordinancesIds = intIds
			state.chosenOrdinancesIds = intIds
		},
		toggleTax(state, id) {
			id = parseInt(id, 10)
			const index = state.chosenOrdinancesIds.indexOf(id)
			if (index === -1) {
				state.chosenOrdinancesIds.push(id)
			} else {
				delete state.chosenOrdinancesIds[index]
			}
		},
	},
	actions: {
		async waitForFinesList() {
			return gettingFinesList
		},
		async getUnitedTaxesList(
			{ commit },
			props = {
				channel: 'AndroidReleaseFines',
				type: 'all',
			}
		) {
			let unitedTaxesList = []
			unitedTaxesList = (await taxesApi.getTaxesUnited({ ...props }))
				.unitedTaxes

			commit('setUnitedTaxesList', unitedTaxesList)
			return unitedTaxesList
		},
		async getTaxesList(
			{ commit },
			props = {
				channel: 'AndroidReleaseFines',
				type: 'all',
				ids: [],
			}
		) {
			// Список налогов, полученных с id-шек с query параметра
			let taxlist = []
			// Список всех налогов на аккаунте
			let allUserTaxes = []

			// Получаем список налогов
			await taxesApi
				.getTaxesList({
					channel: props.channel,
					type: props.type,
				})
				.then(taxesListsResponse => {
					// Может приходить 2 варианта ответа
					if (taxesListsResponse?.taxes?.notpaid?.length > 0) {
						taxesListsResponse.taxes.notpaid.forEach(ordinance => {
							ordinance.amount = Number(ordinance.amount) * 100
							ordinance.amount = Number(Number(ordinance.amount).toFixed(2))
							allUserTaxes.push(ordinance)
						})
					}
					if (taxesListsResponse?.taxes?.closed?.length > 0) {
						taxesListsResponse.taxes.closed.forEach(ordinance => {
							ordinance.amount = Number(ordinance.amount) * 100
							ordinance.amount = Number(Number(ordinance.amount).toFixed(2))
							allUserTaxes.push(ordinance)
						})
					}
					if (taxesListsResponse?.taxes?.length > 0) {
						allUserTaxes = taxesListsResponse.taxes
					}

					taxlist = allUserTaxes
						.filter(tax => {
							// Если айди не заданы то показываем все налоги
							if (props.ids.length === 0) {
								return true
							}
							// Если айди заданы - то показываем только те, что переданы к показу
							return props.ids.includes(tax.id)
						})
						.map(tax => {
							return {
								id: tax.id,
								taxYear: tax.taxYear,
								status: tax.status,
								type: taxTypeToString(tax.type),
								description: tax.typeText,
								uin: tax.ordinanceNumber,
								fullAmount: tax.amount,
								amount: tax.amount,
								date: tax.createdTime,
								inn: tax.inn,
								details: tax.details,
								progress: tax.progress,
								group: tax.group,
								document: tax.document,
							}
						})
				})
				.catch(e => {
					console.log(e)
					gettingFinesListReject()
				})

			commit('setTaxesList', taxlist)
			return taxlist
		},
		chooseTab({ commit }, tabLabel) {
			commit('setActiveTab', tabLabel)
		},
		async getAmountForOrdinances({ state, rootState, commit, dispatch }) {
			await dispatch('waitForFinesList')
			const amountForOrdinancesResponse = await taxesApi
				.getAmountForOrdinances({
					type: 'tax',
					ids: state.chosenOrdinancesIds,
					useBonuses: rootState.bonuses.useBonuses,
					partialAmount: rootState.recurring.partialAmount,
				})
				.catch(e => {
					throw new Error(e)
				})
			if (amountForOrdinancesResponse) {
				commit('setAmountForOrdinances', amountForOrdinancesResponse)
			}
		},
		async toggleTax({ dispatch, commit }, id) {
			commit('toggleTax', id)
			return await dispatch('getAmountForOrdinances', { type: 'tax' })
		},
	},
}
