export default {
	API_BASE_URL: `${process.env.VUE_APP_API_HOST}`,
	API_BASE_OSAGO: `${process.env.VUE_APP_API_HOST}${process.env.VUE_APP_API_BASE_PATH_OSAGO}`,
	API_BASE_FINES: `${process.env.VUE_APP_API_HOST}${process.env.VUE_APP_API_BASE_PATH_FINES}`,
	API_BASE_FINANCE: `${process.env.VUE_APP_API_HOST}${process.env.VUE_APP_API_BASE_PATH_FINANCE}`,
	API_BASE_SUPPORT: `${process.env.VUE_APP_API_HOST}${process.env.VUE_APP_API_BASE_PATH_SUPPORT}`,
	YM_SITEKEY: parseInt(`${process.env.VUE_APP_YM_SITEKEY}`, 10),
	COMMUNICATIONS_WEB_HOST: process.env.VUE_APP_COMMUNICATIONS_WEB_HOST,
	PAYMENT_API_HOST: process.env.VUE_APP_PAYMENT_API_HOST,
}
