import finesApi from '@rosfines/vue-common/common/api/finesApi'
import stringHelper from '@rosfines/core-web/helpers/strings'

let waitForUserNameResolve
const waitForUserName = new Promise(resolve => {
	waitForUserNameResolve = resolve
})

export default {
	namespaced: true,
	state() {
		return {
			surname: null,
			name: '',
			patronymic: '',
		}
	},
	getters: {
		fullNameObj(state) {
			return {
				surname: state.surname === null ? '' : state.surname.trim(),
				name: state.name.trim(),
				patronymic: state.patronymic.trim(),
			}
		},
		fullName(state, getters) {
			const data = getters.fullNameObj
			return `${data.surname ? data.surname : ''} ${data.name} ${data.patronymic ? data.patronymic : ''}`.trim()
		},
		fullShort(state, getters) {
			const data = getters.fullNameObj
			return `${data.surname ? data.surname : ''} ${data.name.substring(0, 1)}. ${data.patronymic ? `${data.patronymic.substring(0, 1)}.` : ''}`
		},
	},
	mutations: {
		saveUserName(state, { surname = '', name = '', patronymic = '' }) {
			state.surname = surname
			state.name = name
			state.patronymic = patronymic
		},
	},
	actions: {
		async waitForUserName() {
			return waitForUserName
		},
		async getUserName({ commit, rootState }) {
			let ids = []
			if (rootState.fines.ordinancesIds?.length > 0) {
				ids = rootState.fines.ordinancesIds
			} else {
				ids = rootState.taxes.ordinancesIds
			}
			const fio = await finesApi.getFIOForOrdinances({
				ids,
				type: rootState.recurring.payment.type,
			})
			if (fio) {
				commit('saveUserName', {
					surname: stringHelper.normalizeFIO(fio.lastName).trim(),
					name: stringHelper.normalizeFIO(fio.firstName).trim(),
					patronymic: stringHelper.normalizeFIO(fio.patronymic).trim(),
				})
				waitForUserNameResolve()
			}
		},
	},
}
